import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//import Paper from "@material-ui/core/Paper";
import { Button, TextField } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 400,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  resize:{
    textTransform: 'upperCase'
  },
}));

export default function CodeActivate(props) {
  const { handleSubmit, errors, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [msgErr, setMsgErr] = React.useState(null);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSubmit = (data) => {
    const solicitudId = JSON.parse(localStorage.getItem("solicitudId"));

    const requestOptions = {
      mode: "cors",
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46YWRtaW4=",
      },
      body: `"${data.code.toString()}"`,
    };

    fetch(
      `${window._env_.API_URL}/solicitudes-registro/${solicitudId}/verificar`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          if (response.status === 400)
            setMsgErr("El Código ingresado no es válido!");
          else if (response.status === 404)
            setMsgErr("Error! Estás sin conexión a internet...");
          else
            setMsgErr("Ohh! algo salió mal, consulte al dpto. de sistemas...");
          setOpen(true);
          return Promise.reject(error);
        }

        localStorage.setItem("codeOk", JSON.stringify(data.respuesta));
        return props.handleNext();
      })
      .catch((error) => {
        console.error("Error! algo salió mal...", error);
      });
  };

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose}>
          {msgErr}
        </Alert>
      </Snackbar>
      <form id="form-code-activate" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="body2" gutterBottom>
            Enviamos un email a <b>{localStorage.getItem("emailContacto")}</b> con un código de 5 dígitos. Ingreselos a continuación
          </Typography>
          <Grid item xs={6} sm={4}>
            <Controller
              as={TextField}
              control={control}
              name="code"
              label="código de activación"
              rules={{
                required: true,
              }}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              defaultValue=""
            />
            {errors.code && (
              <span style={{ color: "red" }}>El código es obligatorio</span>
            )}
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Continuar
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
}
