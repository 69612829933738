import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import PersonalDataForm from "../components/PersonalDataForm";
import CodeActivate from "../components/CodeActivate";
import EmailForm from "../components/EmailForm";
import Thanks from "../components/Thanks";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  logo: {
    width: 200,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [
  "Email",
  "Código de activación",
  "Datos personales",
];

function getStepContent(step, handleNext) {
  switch (step) {
    case 0:
      return <EmailForm handleNext={handleNext} />;
    case 1:
      return <CodeActivate handleNext={handleNext} />;
    case 2:
      return <PersonalDataForm handleNext={handleNext} />;
    case 3:
        return <Thanks />;
    default:
      throw new Error("Error...");
  }
}

export default function Solicitud() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <React.Fragment>
      {activeStep !== steps.length ? (
        <React.Fragment>
          <Typography component="h4" variant="h5" align="center">
            Solicitud de acceso al portal del empleado
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
      </React.Fragment>
      ): ''}
      <React.Fragment>{getStepContent(activeStep, handleNext)}</React.Fragment>
    </React.Fragment>
  );
}
