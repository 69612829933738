import React from "react";

import Reaptcha from "reaptcha";

import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

import InputAdornment from "@material-ui/core/InputAdornment";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { Button, TextField } from "@material-ui/core";

import Link from "@material-ui/core/Link";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  gridPrincipal: {
    marginButton: theme.spacing(5),
  },
  gridSec: {
    paddingTop: theme.spacing(3),
    paddingButton: theme.spacing(2),
  },
  resize:{
    textTransform: 'lowerCase'
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const defaultValues = {
  dominio: "",
  email: "",
};

export default function EmailForm(props) {
  const [msgErr, setMsgErr] = React.useState(null);
  const recaptchaRef = React.useRef();

  const [verified, setVerified] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };


  const { handleSubmit, errors, control } = useForm(defaultValues);

  const onSubmit = (data) => {
    localStorage.setItem("emailContacto", data.email + "@" + data.dominio);
    recaptchaRef.current.reset();
    const requestOptions = {
      mode: "cors",
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46YWRtaW4=",
      },
      body: JSON.stringify({
        tipo: "email",
        valor: data.email + "@" + data.dominio,
      }),
    };
    fetch(`${window._env_.API_URL}/solicitudes-registro`, requestOptions)
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          setMsgErr("Hubo un error al intentar conectarnos a los servidores, por favor intente mas tarde.");
          setOpenNotification(true)
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        localStorage.setItem("solicitudId", JSON.stringify(data.respuesta));
        return props.handleNext();
      })
      .catch((error) => {
        setMsgErr("Hubo un error al intentar conectarnos a los servidores, por favor intente mas tarde.");
        setOpenNotification(true)
        console.error("Error!", error);
      });
  };

  const onVerify = (recaptchaResponse) => {
    setVerified(true);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
    <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert severity="error" onClose={handleCloseNotification}>
          {msgErr}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Typography variant="subtitle2">Tu e-mail institucional</Typography>
          <Typography variant="caption" gutterBottom>
            Es un correo electrónico, en donde se le proporcionará un código de
            validación.
            <br />
            Dicho código nos permite verificar que ud. realmente está iniciando
            el trámite.
          </Typography>

          <Grid item xs={6} sm={4}>
            <FormControl className={classes.formControl}>
              <Controller
                as={TextField}
                control={control}
                rules={{
                  required: true,
                  minLength: 2,
                  pattern: { value: /^[A-Z0-9a-z._-]{2,64}$/i},
                }}
                name="email"
                defaultValue=""
                InputProps={{
                  classes: {
                     input: classes.resize,
                   },
                  endAdornment: (
                    <InputAdornment position="end">@</InputAdornment>
                  ),
                }}
                autoFocus
              />
              {errors.email && errors.email.type === "required" && (
                <span style={{ color: "red", marginTop: 10 }}>
                  El e-mail es obligatorio.
                </span>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <span style={{ color: "red" }}>
                  Acá solo debés poner lo que está antes de la @
                </span>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4}>
            <FormControl className={classes.formControl}>
              <Controller
                control={control}
                name="dominio"
                rules={{ required: true }}
                error={errors.dominio && true}
                defaultValue={""}
                as={
                  <Select>
                    <MenuItem value="unam.edu.ar">unam.edu.ar</MenuItem>
                    <MenuItem value="campus.unam.edu.ar">
                      campus.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fhycs.unam.edu.ar">
                      fhycs.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fceqyn.unam.edu.ar">
                      fceqyn.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fce.unam.edu.ar">
                    fce.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fcf.unam.edu.ar">
                    fcf.unam.edu.ar
                    </MenuItem> 
                    <MenuItem value="fayd.unam.edu.ar">
                      fayd.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fio.unam.edu.ar">
                    fio.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="escenf.unam.edu.ar">
                      escenf.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="correo.unam.edu.ar">
                      correo.unam.edu.ar
                    </MenuItem>    
                    <MenuItem value="eae.unam.edu.ar">
                      eae.unam.edu.ar
                    </MenuItem>  
                    <MenuItem value="mail.unam.edu.ar">
                      mail.unam.edu.ar
                    </MenuItem>   
                    <MenuItem value="editorial.unam.edu.ar">
                      editorial.unam.edu.ar
                    </MenuItem>  
                    <MenuItem value="ibs.unam.edu.ar">
                      ibs.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="fm.unam.edu.ar">
                      fm.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="mupum.unam.edu.ar">
                      mupum.unam.edu.ar
                    </MenuItem>
                    <MenuItem value="transmedia.unam.edu.ar">
                      transmedia.unam.edu.ar
                    </MenuItem>                   
                  </Select>
                }
              />
              {errors.dominio && errors.dominio.type === "required" && (
                <span style={{ color: "red", marginTop: 10 }}>
                  El dominio es obligatorio.
                </span>
              )}
              {errors.dominio && errors.dominio.type === "pattern" && (
                <span style={{ color: "red" }}>
                  Debes poner un dominio de la lista...
                </span>
              )}
            </FormControl>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <div className={classes.gridSec}>
              <Reaptcha
                ref={recaptchaRef}
                sitekey={`${window._env_.RECAPTCHA_SITEKEY}`}
                onVerify={onVerify}
              />
            </div>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                disabled={!verified}
              >
                Continuar
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.gridSec}
        >
          <Typography variant="caption">
            Para continuar es necesario tener un email institucional. Si no recordas o no tenes email institucional{" "}
            <Link onClick={handleClickOpen}>
              <b>ingresa acá</b>
            </Link>
          </Typography>
        </Grid>
      </form>
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Solicitá tu e-mail institucional
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              Para solicitar un e-mail institucional, contactate con un técnico
              de tu unidad académica:
              <ul>
                <li>
                 Rectorado :{" "}
                  <Link href="#">comunicaciones.rectorado@unam.edu.ar</Link>
                </li>        
                <li>
                  FHyCS :{" "}
                  <Link href="#">sistemas@fhycs.unam.edu.ar</Link>
                </li>
                <li>
                  FCEQyN :{" "}
                  <Link href="#">sistemas@fceqyn.unam.edu.ar</Link>
                </li>
                <li>
                  FCE :{" "}
                  <Link href="#">sistemas@fce.unam.edu.ar</Link>
                </li>
                <li>
                  FCF :{" "}
                  <Link href="#">sistemas@fcf.unam.edu.ar</Link>
                </li>
                <li>
                 FIO : <Link href="#">poliszczuk@fio.unam.edu.ar</Link>
                </li>
                <li>
                  FAyD :{" "}
                  <Link href="#">sistemas@fayd.unam.edu.ar</Link>
                </li>  
                <li>                  
                  EE :{" "}
                  <Link href="#">bruno.abracen@escenf.unam.edu.ar</Link>
                </li>     
                <li>
                  EAE :{" "}
                  <Link href="#">renzo@eae.unam.edu.ar</Link>
                </li>                               
              </ul>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Listo
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
