import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { makeStyles, createMuiTheme } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";

import Solicitud from "./screens/Solicitud";
import Inicio from "./screens/Inicio";
import Status from "./screens/Status";

import Footer from "./components/Footer";

import logo from "./logo_unam.png";
import logo2 from "./tupa_logo.png";

import EmailCheck from './screens/EmailCheck';
import Thanks from "./components/Thanks";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  logo: {
    width: 120,
     marginLeft: theme.spacing(5),
  },
  logo2: {
    width: 120,
     marginRight: theme.spacing(5),
     marginLeft: 'auto'
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    minHeight: '70vh',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[600],
    },
  },
});

export default function App() {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <a href='/'><img src={logo} className={classes.logo} alt="UNaM" title="UNaM" /></a>
          <a href='/' className={classes.logo2}><img src={logo2} className={classes.logo2} alt="UNaM" title="UNaM" /></a>
        </Toolbar>
      </AppBar>
      <Router>
        <Switch>
          {/*Homepage*/}
          <Route path="/" exact={true}>
            <Inicio/>
          </Route>
          {/*Main Content */}
          <main className={classes.layout}>        
            <Switch>
              <Paper className={classes.paper}>
                <Route path="/inicio" >
                  <Solicitud />
                </Route>                
                <Route path="/seguimiento/:id">
                  <Status />
                </Route>
                <Route path="/solicitud/:token">
                  <EmailCheck />
                </Route>
                <Route path="/gracias">
                  <Thanks />
                </Route>
              </Paper>
            </Switch>
          </main>
        </Switch>
        
      </Router>
      <Footer />
    </MuiThemeProvider>
  );
}
