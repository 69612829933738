import React from "react";
import { useHistory } from "react-router";

import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import FormLabel from "@material-ui/core/FormLabel";

import { Button } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  error: {
    color: "red",
  },
}));

export default function PersonalDataForm(props) {
  const { handleSubmit, errors, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [msgErr, setMsgErr] = React.useState(null);
  const classes = useStyles();
  const [tipoDoc, setTipoDoc] = React.useState("");
  const [esEmpleado, setDisabled] = React.useState(true);

  const history = useHistory();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleTipoDocChange = (event) => {
    setTipoDoc(event.target.value);
  };

  const [state, setState] = React.useState({
    EXACTAS: false,
    ENFERMERIA: false,
    SOCIALES: false,
    ECONOMICAS: false,
    ARTES: false,
    INGENIERIA: false,
    FORESTALES: false,
    ESCUELA_AGROTECNICA: false,
    EDITORIAL: false,
    TRANSMEDIA:false,
    RADIO: false,
    RECTORADO: false,
  });

  const handleUnidadChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const {
    EXACTAS,
    ENFERMERIA,
    SOCIALES,
    ECONOMICAS,
    ARTES,
    INGENIERIA,
    FORESTALES,
    ESCUELA_AGROTECNICA,
    EDITORIAL,
    TRANSMEDIA,
    RADIO,
    RECTORADO,
  } = state;

  const errorUnidad =
    [
      EXACTAS,
      ENFERMERIA,
      SOCIALES,
      ECONOMICAS,
      INGENIERIA,
      FORESTALES,
      ESCUELA_AGROTECNICA,
      EDITORIAL,
      TRANSMEDIA,
      RADIO,
      RECTORADO,
    ].filter((v) => v).length < 1;

  const onSubmit = (data) => {
    const solicitudId = JSON.parse(localStorage.getItem("solicitudId"));
    const codeOk = JSON.parse(localStorage.getItem("codeOk"));
    const unidades_col = [];
    if (EXACTAS === true) unidades_col.push("EXACTAS");
    if (ENFERMERIA === true) unidades_col.push("ENFERMERIA");
    if (SOCIALES === true) unidades_col.push("SOCIALES");
    if (ECONOMICAS === true) unidades_col.push("ECONOMICAS");
    if (INGENIERIA === true) unidades_col.push("INGENIERIA");
    if (FORESTALES === true) unidades_col.push("FORESTALES");
    if (ESCUELA_AGROTECNICA === true) unidades_col.push("ESCUELA_AGROTECNICA");
    if (EDITORIAL === true) unidades_col.push("EDITORIAL");
    if (TRANSMEDIA === true) unidades_col.push("TRANSMEDIA");
    if (RADIO === true) unidades_col.push("RADIO");
    if (RECTORADO === true) unidades_col.push("RECTORADO");

    const DatosPersonales = {
      contacto: {
        tipo: "email",
        valor: localStorage.getItem("emailContacto"),
      },
      datos_personales: {
        nombre: data.nombres,
        apellido: data.apellidos,
        sexo: data.genero,
        legajo: esEmpleado ? parseInt(data.legajo) : null,
        documentos: [{ tipo: data.tipoDoc, numero: data.documento }],
      },
      emails: [
        {
          tipo: "PERSONAL",
          valor: data.email,
        },
      ],
      telefonos: [
        {
          tipo: "CELULAR",
          uso: "LABORAL",
          numero: parseInt(data.tel),
        },
      ],
      unidades_gestion: [unidades_col.toString()],
    };

    const requestOptions = {
      mode: "cors",
      credentials: "include",
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46YWRtaW4=",
      },
      body: JSON.stringify(DatosPersonales),
    };

    fetch(
      `${window._env_.API_URL}/solicitudes-registro/${solicitudId}`,
      requestOptions
    )
      .then(async (response) => {

        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;

          if (response.status === 400) setMsgErr("Datos incorrectos!");
          else if (response.status === 404)
            setMsgErr("Error! No se pudo encontrar el recurso...");
          else
            setMsgErr("Ohh! algo salió mal, consulte al dpto. de sistemas...");

          setOpen(true);
          return Promise.reject(error);
        }
        if(props.handleNext !== false){
          return props.handleNext();
        }else{
          history.push("/gracias");
        }
      })
      .catch((error) => {
        console.error("Se produjo el siguiente error: ", error);
      });
      
  };

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose}>
          {msgErr}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" gutterBottom>
          Tus datos personales
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              control={control}
              rules={{
                required: true,
                minLength: 2,
                maxLength: 40,
                pattern: {
                  value: /^[a-źA-Ź]{1,}[\ a-źA-Ź']*$/,
                },
              }}
              id="nombres"
              name="nombres"
              label="Nombre"
              fullWidth
              defaultValue=""
            />
            {errors.nombres && errors.nombres.type === "required" && (
              <span className={classes.error}>El Nombre es obligatorio</span>
            )}
            {errors.nombres && errors.nombres.type === "minLength" && (
              <span className={classes.error}>Nombre demasiado corto</span>
            )}
            {errors.nombres && errors.nombres.type === "maxLength" && (
              <span className={classes.error}>Nombre demasiado largo</span>
            )}
            {errors.nombres && errors.nombres.type === "pattern" && (
              <span className={classes.error}>Escriba un nombre válido...</span>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              control={control}
              rules={{
                required: true,
                minLength: 2,
                maxLength: 40,
                pattern: {
                  value: /^[a-źA-Ź]{1,}[\ a-źA-Ź']*$/,
                },
              }}
              id="apellidos"
              name="apellidos"
              label="Apellido"
              fullWidth
              defaultValue=""
            />
            {errors.apellidos && errors.apellidos.type === "required" && (
              <span className={classes.error}>El Apellido es obligatorio</span>
            )}
            {errors.apellidos && errors.apellidos.type === "minLength" && (
              <span className={classes.error}>Apellido demasiado corto</span>
            )}
            {errors.apellidos && errors.apellidos.type === "maxLenght" && (
              <span className={classes.error}>Apellido demasiado largo</span>
            )}
            {errors.apellidos && errors.apellidos.type === "pattern" && (
              <span className={classes.error}>
                Escriba un apellido válido...
              </span>
            )}
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              as={TextField}
              control={control}
              rules={{
                required: true,
                minLength: 5,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i,
                },
              }}
              id="email"
              name="email"
              label="E-mail personal"
              fullWidth
              defaultValue=""
            />
            {errors.email && errors.email.type === "required" && (
              <span className={classes.error}>El Email es obligatorio</span>
            )}
            {errors.email && errors.email.type === "pattern" && (
              <span className={classes.error}>Ingresá un email válido...</span>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              control={control}
              rules={{
                required: true,
                minLength: 3,
                maxLength: 30,
                pattern: { value: /^[0-9]*$/ },
              }}
              id="tel"
              name="tel"
              label="Teléfono"
              fullWidth
              defaultValue=""
            />
            {errors.tel && errors.tel.type === "required" && (
              <span className={classes.error}>El teléfono es obligatorio</span>
            )}
            {errors.tel && errors.tel.type === "pattern" && (
              <span className={classes.error}>
                Ingresá un teléfono válido...
              </span>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="genero-label">Género</InputLabel>
              <Controller
                control={control}
                as={
                  <Select
                    labelId="genero-label"
                    id="genero-select"
                    value={tipoDoc}
                    onChange={handleTipoDocChange}
                  >
                    <MenuItem value="M">MASCULINO</MenuItem>
                    <MenuItem value="F">FEMENINO</MenuItem>
                  </Select>
                }
                name="genero"
                defaultValue=""
              />
              {errors.genero && (
                <span className={classes.error}>Seleccione una opción...</span>
              )}
            </FormControl>
          </Grid>
           <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={esEmpleado}
                    onChange={() => {
                      setDisabled(prev => !prev);
                    }}
                    name="esEmpleado"
                  />
                }
                label="Soy empleado/agente de planta permanente"
              />
          </Grid>
           {esEmpleado && (       
              <Grid item xs={12} sm={12}>
                <Controller
                  as={TextField}
                  control={control}
                  rules={{
                    required: true,
                    minLength: 2,
                    maxLength: 10,
                    pattern: { value: /^[0-9]*$/ },
                  }}
                  id="legajo"
                  name="legajo"
                  label="Legajo"
                  fullWidth
                  defaultValue=""
                />
                {errors.legajo && (
                  <span className={classes.error}>El legajo es obligatorio</span>
                )}
              </Grid>               
            )}     

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="tipo-doc-label">Tipo Doc.</InputLabel>
              <Controller
                control={control}
                as={
                  <Select
                    labelId="tipo-doc-label"
                    id="tipo-doc-select"
                    value={tipoDoc}
                    onChange={handleTipoDocChange}
                  >
                    <MenuItem value="DNI">DNI</MenuItem>
                    <MenuItem value="LC">LC</MenuItem>
                    <MenuItem value="LE">LE</MenuItem>
                  </Select>
                }
                name="tipoDoc"
                defaultValue="DNI"
              />
              {errors.tipoDoc && (
                <span className={classes.error}>
                  El tipo de documento es obligatorio
                </span>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8} sm={9}>
            <FormControl className={classes.formControl}>
              <Controller
                as={TextField}
                control={control}
                rules={{
                  required: true,
                  minLength: 3,
                  maxLength: 15,
                }}
                id="documento"
                name="documento"
                label="Documento"
                autoComplete="documento"
                fullWidth
                defaultValue=""
              />
              {errors.documento && (
                <span className={classes.error}>Documento es obligatorio</span>
              )}
              {errors.documento && errors.documento.type === "minLength" && (
                <span className={classes.error}>Documento demasiado corto</span>
              )}
              {errors.documento && errors.documento.type === "maxLenght" && (
                <span className={classes.error}>Documento demasiado largo</span>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormLabel component="legend">Unidad de desempeño:</FormLabel>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={EXACTAS}
                    onChange={handleUnidadChange}
                    name="EXACTAS"
                  />
                }
                label="FAC. DE CS. EXACTAS QUÍMICAS Y NATURALES"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={ENFERMERIA}
                    onChange={handleUnidadChange}
                    name="ENFERMERIA"
                  />
                }
                label="ESCUELA DE ENFERMERIA"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={SOCIALES}
                    onChange={handleUnidadChange}
                    name="SOCIALES"
                  />
                }
                label="FAC. DE HUM. Y CS. SOCIALES"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={ECONOMICAS}
                    onChange={handleUnidadChange}
                    name="ECONOMICAS"
                  />
                }
                label="FAC. DE CS. ECONOMICAS"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={ARTES}
                    onChange={handleUnidadChange}
                    name="ARTES"
                  />
                }
                label="FACULTAD DE ARTES"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={INGENIERIA}
                    onChange={handleUnidadChange}
                    name="INGENIERIA"
                  />
                }
                label="FACULTAD DE INGENIERIA"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={FORESTALES}
                    onChange={handleUnidadChange}
                    name="FORESTALES"
                  />
                }
                label="FACULTAD DE CS. FORESTALES"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={ESCUELA_AGROTECNICA}
                    onChange={handleUnidadChange}
                    name="ESCUELA_AGROTECNICA"
                  />
                }
                label="ESCUELA AGROTECNICA ELDORADO"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={EDITORIAL}
                    onChange={handleUnidadChange}
                    name="EDITORIAL"
                  />
                }
                label="EDITORIAL UNIVERSITARIA"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={TRANSMEDIA}
                    onChange={handleUnidadChange}
                    name="TRANSMEDIA"
                  />
                }
                label="TRANSMEDIA"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={RADIO}
                    onChange={handleUnidadChange}
                    name="RADIO"
                  />
                }
                label="RADIO FM UNIVERSIDAD"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={RECTORADO}
                    onChange={handleUnidadChange}
                    name="RECTORADO"
                  />
                }
                label="RECTORADO"
              />
            </FormGroup>
            {errorUnidad && (
              <span className={classes.error}>
                Seleccione al menos 1 unidad de gestión
              </span>
            )}
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Continuar
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
}
