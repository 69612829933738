import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    height: '100%',
    margin: 'auto',
    marginBottom: 10,

    display: 'flex',
    flexDirection: 'column',
    boxShadow:'none',
    border:'none',  
    textAlign:'center'    
  },
});

export default function CardsImage(props) {
  const classes = useStyles();
  const Imagen = props.imagen;

  return (
    <Card className={classes.root} variant="outlined">
      
        <CardMedia
          component="img"
          image={Imagen}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.body}
          </Typography>
        </CardContent>
            
    </Card>
  );
}
