import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Paper, Button, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardsImage from "../components/CardsImage";

import Carousel from 'react-material-ui-carousel'
import ResponsiveEmbed from 'react-responsive-embed'

/*Imagenes */
import ImagenQr from '../img/qr.svg';
import ImagenUser from '../img/login.svg';
import ImageSueldo from '../img/recibo.svg';
import unam from '../img/unam.svg';
import DvWaves from '../waves.svg';


const useStyles = makeStyles((theme) => ({
    root:{
        
    },

    inicio: {
        /*texto */
        color: 'white',

        /*imagen de fondo */
        height: '60vh',
        backgroundImage: `url(${unam})`,
        background: '#046c95',
        backgroundPosition: '0% 10%', 
        backgroundSize: '250px;',
        backgroundRepeat: 'repeat',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        borderRadius: 1,
    },
    contact: {
        color: 'white',
        /*imagen de fondo */
        height: 300,
        background: '#303846',
        backgroundPosition: 'center', 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        borderRadius: 1,
    },

    tituloInicio: {
        paddingBottom: 10,
        fontWeight: 'bold',
        fontFamily: 'Montserrat'
        
    },

    botonInicio: {
        padding: 20,
    },

    info: {
        padding: 10,
        marginBottom: 20,
        background: '#fff',
                
    },
    cardGrid: {
        marginBottom: 20,
    },
    separator: {
        background: 'rgba(17, 25, 40, 0.5)',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 3.5px )',
        border: '0px solid rgba( 255, 255, 255, 0.18 )',
        background:'#fff',
        
    },
    separatorText1: {
        textAlign: 'center',
        color: '#333',
        position: 'relative',
        top: 0,
        padding:60,
        fontWeight:'bold',
        fontFamily:'Montserrat'

    },
    separatorText2: {
        textAlign: 'center',
        color: '#333',
        padding: 60,
        fontWeight:'bold',
        fontFamily:'Montserrat'
    },

    heading:{
        color:'#046c95'
    },
    fillContainer: {
        
    },

    fillContainer2: {
        backgroud:'#f5f6f7',
        paddingBottom: 60
    },
    curve: {
        paddingTop: 15,
        background:'#fff',
    },

    curveNegative: {
        backgroundImage: `url(${DvWaves})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        "&::before":{
            content: "",
            WebkitTransform: 'rotate(180deg)'
        }
        
    },

    faqs:{
        height: '100%',
        width: '71.5%',
        margin: 'auto',
        paddingTop: 10,
        paddingBottom: 45
    },

    tutos_container:{
        height: '100%',
        width: '71.5%',
        margin: 'auto',
        paddingTop: 10,
        paddingBottom: 45,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },

    userExist:{
        "& a":{
            color:'white',
        },
        paddingTop: 10,
        paddingBottom: 10,
    },

    tutos:{
        paddingLeft: 30,
        paddingRight: 30,
        width: '100%'
    }

}));


export default function Inicio() {
    const classes = useStyles();
    return(
        
        <Grid container >
            {/*Portada Inicio */}
            <Grid item xs={12} >
                <Paper className={classes.inicio}>
                    <Grid container alignItems="center" justify="center" >               
                        <Grid item sm={6} xs={10} >
                            <Typography variant="h2" className={classes.tituloInicio} >Usuarios</Typography>
                            <Typography variant="body1">La creación de una cuenta de usuario te permite acceder de forma simple, por medio de la identidad universitaria, a los diferentes sistemas y servicios de la Universidad Nacional de Misiones. </Typography>
                            
                            <div className={classes.botonInicio}>
                                <Button color="primary" size='large' variant="contained" href="/inicio" disableElevation>Crear Usuario</Button>
                                <Typography className={classes.userExist}>¿Ya tienes un usuario? <a href={`${window._env_.PORTAL_URL}`}>Acceder</a></Typography>
                            </div>
                        </Grid>                                                
                    </Grid>
                </Paper>
            </Grid>
            <Grid container className={classes.fillContainer}>
                {/*Cards con Info */}
                <Grid item xs={12} className={classes.curve}>                    
                    <Typography variant="h3" className={classes.separatorText1} >Beneficios</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.info} justify="center">
                        <Grid item sm={3} xs={12} className={classes.cardGrid}>
                            <CardsImage
                                imagen={ImagenUser}
                                title='Acceso al Portal de Empleados'
                                body='Con tu usuario podras acceder al portal de empleados donde podrás acceder a los diferentes sistemas de la institución.' 
                            />
                        </Grid>
                        <Grid item sm={3} xs={12} className={classes.cardGrid}>
                            <CardsImage
                                imagen={ImageSueldo}
                                title='Recibo de Sueldo Online'
                                body='Desde el portal de empleados la Universidad pone a disposición de los Docentes y No Docentes los recibos de sueldo de manera digital. ' 
                            />
                        </Grid>                    
                        <Grid item sm={3} xs={12} className={classes.cardGrid}>
                            <CardsImage                             
                                imagen={ImagenQr}
                                title='Pase Covid'
                                body='Podrás generar y tener a disposición tu pase para ingresar a la institución como establecen los protocolos.' 
                            />
                        </Grid>
                        
                    </Grid>                                    
                </Grid>
            </Grid>
            <Grid container className={classes.fillContainer2}>
                {/*FAQs */}
                <Grid item xs={12} className={classes.curveNegative}>
                </Grid>
                <Grid item xs={12} className={classes.a}>
                    <Typography variant="h3" className={classes.separatorText2}>Preguntas Frecuentes</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.faqs} justify="center">
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading}>¿Qué es mi usuario?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Este usuario es tu identidad digital universitaria, es decir, un identificador personal y único para el personal de la Universidad Nacional de Misiones.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                    <Typography className={classes.heading}>¿Para qué sirve mi usuario?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Este usuario te permite acceder de forma simple al Portal del Usuario. En él, podrás acceder a los diferentes sistemas y servicios que ofrece la Universidad Nacional de Misiones.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                                >
                                <Typography className={classes.heading}>¿Cómo creo mi usuario?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Para crear tu usuario hacé click en <a href='/inicio'>CREAR USUARIO</a> y seguí los pasos, el proceso te va a ir guiando hasta el final. Ante cualquier duda en la creación del usuario único consultá el tutorial, o finalmente preguntá al administrador técnico de tu dependencia.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                                >
                                <Typography className={classes.heading}>¿Si no recuerdo mi usuario o contraseña?
                                </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    Para recuperar tu usuario o tu contraseña, podés usar la opción “Olvidé mi contraseña” y recibirás en tu email tu usuario y los pasos necesarios para cambiar tu contraseña. Ante cualquier duda para recuperar el acceso al portal consultá el tutorial.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header"
                                >
                                <Typography className={classes.heading}>¿Cómo accedo al Portal del Usuario?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Typography>
                                    El Portal del Usuario es público y se puede acceder desde la dirección web <a href={`${window._env_.PORTAL_URL}`}>{`${window._env_.PORTAL_URL}`}</a>. Al ingresar, te pedirá que coloques los datos de tu “usuario” y de tu “contraseña” que generaste previamente. Ante cualquier duda en el acceso al portal consultá el tutorial.
                                </Typography>
                                </AccordionDetails>
                            </Accordion>                            
                        </Grid>                        
                    </Grid>                                    
                </Grid>
                
                {/*Tutoriales */}
                <Grid item xs={12} className={classes.a}>
                    <Typography variant="h3" className={classes.separatorText2}>Tutoriales</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.tutos_container} justify="center">
                        <Grid item xs={12} >
                            <Carousel navButtonsAlwaysVisible={true} animation="slide" autoPlay={false} className={classes.tutos}
                                        navButtonsProps={{ 
                                            style: {
                                                backgroundColor: 'black',
                                                borderRadius: 25,
                                                height: 30,
                                                width: 30,
                                                
                                            }
                                        }}
                            >
                                
                                <ResponsiveEmbed className={classes.tutos}
                                    src='https://youtube.com/embed/2ypOhTmZMXA'
                                    title="Guía para solicitar acceso al Portal del empleado UNaM"
                                    allowFullScreen
                                    />
                                <ResponsiveEmbed className={classes.tutos}
                                    src='https://youtube.com/embed/cyqEw4pS0ec'
                                    title="Guía para establecer su clave para acceder al Portal del empleado UNaM"
                                    allowFullScreen
                                    />
                                <ResponsiveEmbed className={classes.tutos}
                                    src='https://youtube.com/embed/Mz8TGE3aTGQ'
                                    title="Guía rápida para descargar recibos de sueldo en el Portal UNaM"
                                    allowFullScreen
                                    />
                                
                            </Carousel> 
                        </Grid>                        
                    </Grid>                                    
                </Grid>
            </Grid>           
        </Grid>            
            
        
        
    );
}
