import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  spacing: {
    marginTop: theme.spacing(4),
  },
}));

export default function Thanks() {
  const classes = useStyles();

  const solicitudId = JSON.parse(localStorage.getItem("solicitudId"));

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h5" gutterBottom>
        Su solicitud fue registrada exitosamente!
      </Typography>
      <br />
      <Typography variant="subtitle1" gutterBottom>
        Dentro de las próximas horas estaremos procesando la petición.
      </Typography>
      <Typography variant="body1" className={classes.spacing} gutterBottom>
        Recibirás un email en <b>{localStorage.getItem("emailContacto")}</b> con información de como acceder al portal del
        empleado con tu usuario.
      </Typography>
      
      <Typography variant="body2"  className={classes.spacing} gutterBottom>
      Para más información de su solicitud, dirijase a:<br />
        <Link to={`/seguimiento/${solicitudId}`} >
          Seguir mi solicitud
        </Link>
      </Typography>
    </div>
  );
}
