import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid, makeStyles } from "@material-ui/core";
import unam from '../img/unam.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 60,
    background: '#303846',
    textAlign: 'center',
    color:"#f9f9f9;",
    backgroundImage: `url(${unam})`,
    backgroundPosition: 'center',
    backgroundSize: '50vh;',
    backgroundRepeat: 'no-repeat',
  },
  text: {
    fontSize: 15,
    color: "#f9f9f9;",    
  },
  imagenContacto: {
    width:100,
    textAlign:"center"
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justify="center" className={classes.root}>
      <Grid item xs={12} >        
        <Typography variant="h3" className={classes.titulo_inicio}>Contacto</Typography>
        <Typography variant="body1">Ante cualquier consulta enviá un correo electronico a <b>sistemas@unam.edu.ar</b> </Typography>
        <br></br>
        <Typography variant="body2" color="textSecondary" className={classes.text}>
          {"Desarrollado por el departamento de sistemas - Rectorado UNaM "}
        </Typography>
      </Grid>
    </Grid>
    
  );
}
