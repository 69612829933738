import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { Alert, AlertTitle } from "@material-ui/lab";

import PersonalDataForm from "../components/PersonalDataForm";

const EmailCheck = () => {
  const { token } = useParams();

  const tokenDecode = atob(token);
  const aToken = tokenDecode.split(",");
  const solicitudId = localStorage.setItem(
    "solicitudId",
    JSON.stringify(aToken[0])
  );
  console.log(localStorage.getItem("solicitudId"));
  const code = JSON.stringify(aToken[1]);
  // verificar enlace
  useEffect(() => {
    fetch(
      `${window._env_.API_URL}/solicitudes-registro/${aToken[0]}/verificar`,
      {
        mode: "cors",
        credentials: "include",
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46YWRtaW4=",
        },
        body: `"${aToken[1]}"`,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          if (response.status === 400)
            console.log("El Código ingresado no es válido!");
          else if (response.status === 404)
            console.log("Error! Estás sin conexión a internet...");
          else
            console.log(
              "Ohh! algo salió mal, consulte al dpto. de sistemas..."
            );
          return Promise.reject(error);
        }
        console.log("VERIFICACION CORRECTA");
        localStorage.setItem("codeOk", JSON.stringify(data.respuesta));
      })
      .catch((error) => {
        console.error("Error! algo salió mal...", error);
      });
  });
  // ..
  return (
    <React.Fragment>
      <Alert severity="success">
        <AlertTitle>Tu correo fue verificado!</AlertTitle>
        Solomente te falta completar este formulario para terminar la solicitud.
      </Alert>
      <br />
      <PersonalDataForm handleNext={false} />
    </React.Fragment>
  );
};
export default EmailCheck;
