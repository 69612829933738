import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from 'moment';

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  pendiente: {
    color: "red",
  },
  verificado: {
    color: "#c49000",
  },
  enviado: {
    color: "#087f23",
  },
  gris: {
    color: "lightgrey",
  },
  success: {
    color: "#c49000",
  },
});

export default function Status() {
  let { id } = useParams();
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(
      `${window._env_.API_URL}/solicitudes-registro/${id}/seguimiento`
    ).then(async (response) => {
      const statusList = await response.json();
      setData(statusList);
    });
  }, [id]);

  const stylize = (estado, key) => {

    switch (estado) {
      case "SOLICITUD_PENDIENTE":
        return <span>PEDIDO INICIAL</span>;
      case "SOLICITUD_VERIFICADO":
        return <span>TU CORREO FUE VERIFICADO</span>;
      case "SOLICITUD_ENVIADO":
        return <span>SOLICITUD ENVIADA</span>;
      case "REGISTRO_PROCESANDO":
        return <span>PROCESANDO SU SOLICITUD</span>;
      case "ESTADO_REGISTRO_VINCULAR":
        return <span>TU SOLICITUD FUE APROBADA</span>;
      case "ESTADO_REGISTRO_APROBADO":
        return <span>TU SOLICITUD FUE APROBADA</span>;
      case "ESTADO_REGISTRO_RECHAZADO":
        return <span>TU SOLICITUD RECHAZADA</span>;
      default:
        return estado.split("_").join(" ");
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        Seguimiento de tu solicitud
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="center">Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  <CheckCircleOutline
                    fontSize="inherit"
                    style={{
                      fontSize: "24px",
                      color: "#4caf50",
                      alignItems: "center",
                    }}
                  />
                </TableCell>
                <TableCell align="left">{stylize(item.estado, key)}</TableCell>
                <TableCell align="right">{moment(item.fecha).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
